import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './componet/Home';
import Forms from './routes/Forms';
import Aboutus from './routes/Aboutus';
import Contactlink from './routes/Contactlink';
import Faqslink from './routes/Faqslink';
import Tableswin from './routes/Tableswin';
import Dashboard from './componet/Dashboard';
import ProtectedRoute from './componet/ProtectedRoute';
import Form from '../src/form/Form';
function App() {
  const [user, setUser] = useState(null);
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='about' element={<Aboutus />} />
        <Route path='faq' element={<Faqslink />} />
        <Route path='contact' element={<Contactlink />} />
        <Route path='forms' element={<Forms />} />
        <Route path='form' element={<Form setUser={setUser}></Form>} />
        <Route path='winners' element={<Tableswin />} />

        <Route path='dashboard' element={<Dashboard user={user}></Dashboard>} />
      </Routes>
    </>
  );
}

export default App;
